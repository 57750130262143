<template>
    <div class="container">
        <div class="filter">
            <div class="filter-item" v-for="(item, index) in filters" :key="index">
                <div class="filter-name" :style="{ minWidth: labelWidth }">{{ item.name }}</div>
                <div class="filter-content">
                    <el-tag
                        size="mini"
                        @click="changeFilter(item.key, '')"
                        :effect="filterData[item.key] ? 'plain' : 'dark'"
                    >
                        全部
                    </el-tag>
                    <el-tag
                        size="mini"
                        v-for="(child, childIndex) in item.list"
                        :key="childIndex"
                        @click="changeFilter(item.key, child.value)"
                        :effect="filterData[item.key] === child.value ? 'dark' : 'plain'"
                    >
                        {{ child.label }}
                    </el-tag>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="sort">
                <div class="sort-btn">
                    <div class="sort-item" @click="sort = {}" :class="{ active: Object.keys(sort).length === 0 }">
                        综合排序
                    </div>
                    <template v-for="(item, index) in sortKey">
                        <div
                            class="sort-item right"
                            @click="clickSort(item.key)"
                            :key="index"
                            :class="showClass(item.key)"
                        >
                            <span>{{ item.name }}</span>
                            <i class="el-icon-caret-top"></i>
                            <i class="el-icon-caret-bottom"></i>
                        </div>
                    </template>
                </div>

                <div class="flex1">
                    <search-input
                        size="mini"
                        v-model="search"
                        placeholder="请输入所属领域或其他任意信息"
                        @suffixClick="getData"
                        @keyup.enter.native="getData"
                        clearable
                        suffix-icon="el-icon-search"
                        class="search"
                    ></search-input>
                </div>

                <div class="btn-right" v-if="hasAction">
                    <el-button size="small" type="primary" @click="show = true">
                        <img src="../../assets/home_icon_gongjifabu@3x (3).png" alt="" class="button_img" />
                        <div class="button_title">信息发布</div>
                    </el-button>
                    <el-button v-if="isDemand" size="small" type="primary" @click="btnEvent">
                        <img src="../../assets/home_icon_xuqiufabu@3x (3).png" alt="" class="button_img" />
                        <div class="button_title">需求发布</div>
                    </el-button>
                    <el-button v-else size="small" type="primary" @click="btnEvent">
                        <img src="../../assets/home_icon_xuqiufabu@3x (3).png" alt="" class="button_img" />
                        <div class="button_title">供给发布</div>
                    </el-button
                    >
                </div>
            </div>
            <div class="list" v-loading="fetchingData">
                <slot></slot>

                <div class="empty" v-if="empty && !fetchingData">
                    <i class="el-icon-takeaway-box"></i>
                    <div class="empty-text">暂无数据</div>
                </div>
            </div>
            <div class="footer">
                <el-pagination
                    @size-change="onSizeChange"
                    @current-change="onCurrentChange"
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalElements"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="isDemand ? '发布需求信息' : '发布供给信息'" :visible.sync="show" center>
            <el-form :rules="rules" label-width="70px" hide-required-asterisk>
                <el-form-item prop="patent" :label="isDemand ? '需求类别' : '供给类别'">
                    <el-select v-model="formData.patent" placeholder="请选择">
                        <el-option
                            v-for="item in isDemand ? demand : parent"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="btns">
                <!-- <el-button type="info" size="small" @click="show = false">返回</el-button> -->
                <el-button v-if="isDemand" type="primary" size="small" @click="Onsaves">确定</el-button>
                <el-button v-else type="primary" size="small" @click="Onsave">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SearchInput from '../SearchInput';
import pageableTable from '../../mixins/pageableTable';
export default {
    name: 'List',
    props: {
        url: {
            type: String,
            default: ''
        },
        filters: {
            type: Array,
            default: () => {
                return [
                    {
                        name: '应用领域',
                        key: 'field',
                        list: [{ label: '研发阶段', value: 'RESEARCH_AND_DEVELOPMENT' }]
                    }
                ];
            }
        },
        labelWidth: {
            type: String,
            default: '52px'
        },
        sortKey: {
            type: Array,
            default: () => {
                return [
                    {
                        name: '发布时间',
                        key: 'createdAt'
                    }
                ];
            }
        },
        sortKeys: {
            type: Array
        },
        isDemand: {
            type: Boolean,
            default: false
        },
        hasAction: {
            type: Boolean,
            default: true
        },
        defaultQuery: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    mixins: [pageableTable],
    data() {
        return {
            search: '',
            filterData: {},
            sortList: ['desc', 'asc'],
            rules: {
                patent: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            formData: {},
            supplys: [],
            demands: [],
            show: false
        };
    },
    computed: {
        // newsType() {
        //     return this.tabs == '平台动态' ? 324 : 325;
        // },
        parent() {
            return [...this.supplys].filter((item, index) => {
                return index < 6;
            });
        },
        demand() {
            return [...this.demands].filter((item, index) => {
                return index < 7;
            });
        }
    },
    watch: {
        $route() {
            this.$emit('init');
        }
    },
    created() {
        if(this.sortKeys){
            this.sortStr = 'digest,desc'
        }else{
            this.sortStr = 'createdAt,desc'
        }
        this.$nextTick(() => {
            this.init();
        });
        this.$http.post('/navigation/supply').then(res => {
            this.supplys = res;
        });
        this.$http.post('/navigation/demand').then(res => {
            this.demands = res;
        });
    },
    methods: {
        Onsave() {
            if (!this.formData.patent) {
                this.$message.error('请选择类别');
                return;
            }
            this.$store.commit('updateShowAdd', true);
            if (this.formData.patent == 157) {
                this.$router.push('/patent?caseType=DECLASSIFICATION');
                this.show = false
            } else if (this.formData.patent == 158) {
                this.$router.push('/patent?caseType=GENERAL');
                this.show = false
            } else if (this.formData.patent == 159) {
                this.$router.push('/logo');
                this.show = false
            } else if (this.formData.patent == 160) {
                this.$router.push('/copyright?type=COPY');
                this.show = false
            } else if (this.formData.patent == 161) {
                this.$router.push('/copyright?type=SOFT');
                this.show = false
            } else if (this.formData.patent == 162) {
                this.$router.push('/technologyProduct?tpType=TECHNOLOGY');
                this.show = false
            } else if (this.formData.patent == 163) {
                this.$router.push('/technologyProduct?tpType=PRODUCT');
                this.show = false
            }
        },
        Onsaves() {
            if (!this.formData.patent) {
                this.$message.error('请选择类别');
                return;
            }
            this.$store.commit('updateShowAdd', true);
            if (this.formData.patent == 167) {
                this.$router.push('/patentDemand?caseType=DECLASSIFICATION');
                this.show = false
            } else if (this.formData.patent == 168) {
                this.$router.push('/patentDemand?caseType=GENERAL');
                this.show = false
            } else if (this.formData.patent == 169) {
                this.$router.push('/logoDemand');
                this.show = false
            } else if (this.formData.patent == 170) {
                this.$router.push('/copyrightDemand?type=COPY');
                this.show = false
            } else if (this.formData.patent == 171) {
                this.$router.push('/copyrightDemand?type=SOFT');
                this.show = false
            } else if (this.formData.patent == 172) {
                this.$router.push('/techProductDemand?tpType=TECHNOLOGY');
                this.show = false
            } else if (this.formData.patent == 173) {
                this.$router.push('/techProductDemand?tpType=PRODUCT');
                this.show = false
            }
        },
        init() {
            let info = {};

            [...this.filters].forEach(item => {
                info[item.key] = '';
            });

            this.filterData = info;

            this.$nextTick(() => {
                this.page = 1;
                this.getData();
            });
        },
        clickInput() {
            console.log('ahggsgd');
        },
        beforeGetData(query) {
            console.log(this.$route);
            let data = {};
            [...this.filters].forEach(item => {
                if (!item.noQuery) {
                    query[item.key] = this.filterData[item.key];
                } else {
                    data[item.key] = this.filterData[item.key];
                }
            });
            data.query = { ...query, ...this.defaultQuery };

            data.search = this.search;
            if (!this.$route.meta.noCheckStatus) {
                data.query.status = 'PASS';
            }

            return data;
        },
        changeFilter(key, val) {
            this.filterData[key] = val;
            this.getData();
        },
        showClass(key) {
            let _class = '';
            if (this.sort[key]) {
                _class += 'active ';
                _class += this.sort[key];
            }

            return _class;
        },
        clickSort(key) {
            console.log(key);
            let index = -1;
            if (this.sort[key]) {
                index = this.sortList.indexOf(this.sort[key]);
            }

            this.changeSort(key, this.sortList[(index + 1) % 2]);
        },
        btnEvent() {
            this.$emit('btnEvent',true);
        },
        setList(list) {
            this.$emit('input', list);
        }
    },
    components: {
        SearchInput
    }
};
</script>

<style lang="less" scoped>
.container {
    .flex-col();
}
.sort {
    height: 56px;
    background-color: #fff;
    flex-shrink: 0;
    padding: 0 10px;
}

.main {
    background-color: #fff;
    flex-grow: 1;
    margin-top: 20px;
    .flex-col();
}
.list {
    flex-grow: 1;
    min-height: 740px;
}
.footer {
    align-self: center;
    padding: 50px;
    flex-shrink: 0;
}

.empty {
    padding: 300px 0;
    .flex-col();
    align-items: center;
    i {
        font-size: 100px;
        color: #939599;
    }

    .empty-text {
        font-size: 14px;
        color: #939599;
        margin-top: 20px;
    }
}
.el-tag--dark {
    background: #01a041;
    border-radius: 2px;
}
.sort {
    .flex();
    padding: 0 10px;
}
.sort-btn {
    .flex();
    align-items: stretch;
    height: 56px;
    line-height: 56px;
    .sort-item {
        padding: 0 10px;
        color: #313233;
        font-size: 13px;
        cursor: pointer;

        &.right {
            position: relative;
            padding-right: 20px;

            i {
                position: absolute;
                right: 5px;
                color: #313233;
                font-size: 8px;

                &.el-icon-caret-top {
                    bottom: 25px;
                }
                &.el-icon-caret-bottom {
                    top: 26px;
                }
            }

            &.desc {
                .el-icon-caret-bottom {
                    color: #01a041;
                }
            }

            &.asc {
                .el-icon-caret-top {
                    color: #01a041;
                }
            }
        }

        &:hover {
            color: #01a041;
        }

        &.active {
            color: #01a041;
        }
    }
}
.flex1 {
    flex-grow: 1;
    .flex();
    padding: 0 20px;

    .text {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 17px;
        margin-left: 20px;
    }
}
.search {
    width: 260px;
    /deep/.el-input__inner {
        background-color: @bg;
        border-color: @bg;
        &:focus {
            border-color: #01a041;
        }
    }
    ::-webkit-input-placeholder{
        color: #313233;
    }
}
/deep/ .el-dialog {
    width: 760px;

    .el-form {
        width: 390px;
        margin: 0 auto;
    }

    .el-form-item {
        width: 100%;
        display: block !important;
        .el-select {
            width: 100%;
        }
        .el-input {
            width: 100%;
        }
    }

    .btns {
        padding-top: 30px;
    }
}
.filter {
    background-color: #fff;
    padding: 20px 20px 9px 20px;

    .filter-item {
        .flex();
        align-items: flex-start;
        .filter-name {
            flex-shrink: 0;
            font-size: 13px;
            color: #939599;
            line-height: 22px;
        }

        .filter-content {
            margin-left: 26px;
            .flex();
            flex-wrap: wrap;

            /deep/ .el-tag {
                cursor: pointer;
                border-width: 0px;
                line-height: 22px;
                &.el-tag--plain {
                    border-width: 0;
                    color: #313233;
                    &:hover {
                        color: #01a041;
                    }
                }
                margin-bottom: 10px;
                margin-right: 15px;

                &.el-tag--mini {
                    padding: 0 4px;
                    height: 22px;
                    border-radius: 2px;
                }
            }
        }
    }
}

.btn-right {
    padding-right: 10px;

    .el-button + .el-button {
        margin-left: 20px;
    }

    /deep/ .el-button {
        padding: 0px 15px;
        line-height: 36px;
        min-width: 130px;
        border-radius: 2px;
        i {
            font-size: 18px;
            vertical-align: middle;
        }
        span {
            vertical-align: middle;
        }
    }
}
/deep/ .el-pager li.active {
    color: #01a041 !important;
}
.el-button {
    position: relative;
}
.button_img {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 6px;
    left: 24px;
}
.button_title {
    font-size: 12px;
    color: #fff;
    margin-left: 30px;
}
</style>
